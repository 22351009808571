<template>
  <div>
    <b-card>
      <!-- FILTER START -->
      <b-row>
        <b-col md="4">
          <b-form-group rules="required">
            <label class="mr-1">{{ $t("labels.datefrom") }}</label>

            <flat-pickr v-model="filterSelect.dateFrom" class="form-control" :config="{
              altInput: true,
              altFormat: 'd-m-Y H:i',
              enableTime: true,
              time_24hr: true,
            }" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group rules="required">
            <label class="mr-1">{{ $t("labels.dateto") }}</label>
            <flat-pickr v-model="filterSelect.dateTo" class="form-control" :config="{
              altInput: true,
              altFormat: 'd-m-Y H:i',
              enableTime: true,
              time_24hr: true,
            }" />
            <!-- <label class="mr-1 text-danger" v-if="filterSelect.dateFrom"
              >Rango maximo de 30 dias
            </label> -->
          </b-form-group>
        </b-col>

        <b-col v-if="typeUserLoged == 'Root'" md="4">
          <b-form-group>
            <label class="mr-1 text-lg" for="customRadioInline1">Clientes
              <span class="text-danger">*</span>
            </label>
            <v-select v-model="filterSelect.selectCustomer" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerOptions" label="name" :reduce="(customer) => customer._id"
              placeholder="Seleccione un cliente" value="">
              <template #option="{ name }">
                <feather-icon icon="UserIcon" size="16" class="align-middle mr-50" />
                <span> {{ name }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <!-- <b-col md="4">
          <b-form-group>
            <label class="mr-1" for="customRadioInline1"
              >Productos
              <span class="text-danger">*</span>
            </label>
            <v-select
              v-model="filterSelect.selectProduct"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productsOptions"
              label="name"
              :reduce="(product) => product.productId"
              placeholder="Select product"
              value=""
            />
          </b-form-group>
        </b-col> -->
        <b-col md="2">
          <b-form-group>
            <label class="mr-1">
              Monedas
              <span class="text-danger">*</span>
            </label>
            <v-select v-model="filterSelect.selectCurrency" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currencyssOptions" placeholder="Seleccione la moneda" value="Bs">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label class="mr-1">Buscador</label>
            <b-form-input v-model="filterSelect.q" class="d-inline-block mr-1" :placeholder="$t('labels.search')" />
          </b-form-group>
        </b-col>
        <b-col md="4" class="d-flex align-items-center justify-content-end">
          <b-button :disabled="loadingData" @click="inputSelect()" class="text-nowrap" variant="primary">
            <b-spinner small v-if="loadingData"></b-spinner>
            <feather-icon v-if="!loadingData" icon="SearchIcon" class="mr-50" />
            <span v-if="!loadingData" class="text-nowrap">{{ $t('labels.search') }}</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- <b-row class="mb-1">
          <b-col class="d-flex align-items-center justify-content-end">
            <b-button 
                      :disabled="loadingData"
                      @click="inputSelect()"
                      class="text-nowrap"
                      variant="primary">
              <b-spinner small v-if="loadingData"></b-spinner>
              <feather-icon v-if="!loadingData" icon="SearchIcon" class="mr-50" />
              <span v-if="!loadingData" class="text-nowrap">{{ $t('labels.search') }}</span>
            </b-button>
          </b-col>
        </b-row> -->
      <!-- FILTER END -->
    </b-card>
    <b-row>
      <b-col v-for="(item, index) in cardCabezera" :key="index" lg="3" sm="6">
        <statistic-card-horizontal :color="item.color" :icon="item.icon" :statistic="item.statistic"
          :statistic-title="item.title" />
      </b-col>
    </b-row>

    <b-card>
      <b-overlay :show="loadingData" variant="transparent" no-wrap />
      <b-modal id="modalResponse" title="PopUp Response" cancel-variant="outline-secondary" scrollable>
        <b-card-text>
          {{ rows }}
        </b-card-text>
      </b-modal>

      <!-- <b-row class="mb-1">
        <b-col md="6"></b-col>
        <b-col md="6" class="d-flex align-items-center justify-content-end">
          <b-button @click="downloadFile()"
                    class="text-nowrap"
                    :variant="tableData.length > 0 ? 'primary' : 'secondary'"
                    :disabled="tableData.length > 0 ? false : true" >
            <b-spinner small v-if="exportDataLoading"></b-spinner>
            <feather-icon v-if="!exportDataLoading" :icon="tableData.length > 0 ? 'DownloadIcon' : 'XIcon'" class="mr-50" />
            <span v-if="!exportDataLoading" class="text-nowrap">{{ $t('labels.export') }}</span>
          </b-button>
        </b-col>
      </b-row> -->

      <!-- table -->
      <vue-good-table mode="remote" :columns="columns" :rows="tableData" :totalRows="totalRows"
        :pagination-options="{ enabled: true }" :sort-options="{ enabled: false }" compactMode>
        <template slot="table-row" slot-scope="props">
          <div class="text-center"
            v-if="['debit', 'credit', 'winlose', 'rakeBruto', 'rakeNetoCliente', 'rakeNetoProveedor', 'totalPagar', 'balancePrev', 'balanceLater'].includes(props.column.field)">
            <span :class="props.row[props.column.field] >= 0 ? 'text-success' : 'text-danger'" class="badge">{{
              formatTwoDecimals(props.row[props.column.field]) }}</span>
          </div>
          <div class="text-center">
            <span v-if="props.column.field == 'totalPagar'"
              :class="props.row[props.column.field] >= 0 ? 'text-success' : 'text-danger'" class="badge"> {{
                totalPagarUSD(props.row) | currency({ symbol: "" }) }} USD </span>
            <span v-if="props.column.field == 'winlose'"
              :class="props.row[props.column.field] >= 0 ? 'text-success' : 'text-danger'" class="badge"> {{
                totalWinloseToUSD(props.row) | currency({ symbol: "" }) }} USD </span>
            <span v-if="props.column.field == 'debit'"
              :class="props.row[props.column.field] >= 0 ? 'text-success' : 'text-danger'" class="badge"> {{
                totalSalesToUSD(props.row) | currency({ symbol: "" }) }} USD </span>
            <span v-if="props.column.field == 'credit'"
              :class="props.row[props.column.field] >= 0 ? 'text-success' : 'text-danger'" class="badge"> {{
                totalWinsToUSD(props.row) | currency({ symbol: "" }) }} USD </span>

          </div>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select v-model="serverParams.limit" :options="pages" class="mx-1" @input="onPerPageChange" />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>

            <!-- pagination -->
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="serverParams.limit" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="onPageChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
     
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BSpinner
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import reportsStoreModule from "@/store/reports";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { formatDate } from '@core/utils/filter'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import moment from "moment";
import { mapActions } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    BSpinner,
    ToastificationContent,
    StatisticCardHorizontal,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      exportDataLoading: false,
      timeOutSearchCustomer: null,
      showFilter: false,
      loadingData: false,
      tableData: [],
      pages: [20, 40, 60],
      columns: [
        { label: "ID Usuario", field: "userId" },
        { label: "Username", field: "username" },
        { label: "Apuesta", field: "debit" },
        { label: "Premio", field: "credit" },
        { label: "Win/Lose", field: "winlose" },
        { label: "Rake Bruto", field: "rakeBruto" },
        { label: "Rake Neto Cliente(81%)", field: "rakeNetoCliente" },
        { label: "Rake Neto Proveedor(19%)", field: "rakeNetoProveedor" },
        { label: "Total a pagar", field: "totalPagar" },
      ],
      rows: [],
      serverParams: {
        page: 1,
        limit: 20,
        product: "evpoker",
      },
      totalRows: 0,
      customerOptions: [],
      productsOptions: [],
      currencyssOptions: [
        'USD',
        'ARS',
        'BRL',
        'CLP',
        'COP',
        'EUR',
        'MXN',
        'MYR',
        'PEN',
        'PYG',
        'UYU',
        'VES',
        'XAF'
      ],
      filterSelect: {
        dateTo: new Date(),
        dateFrom: new Date(),
        selectCustomer: "",
        selectProduct: "evpoker",
        selectCurrency: "USD",
        q: "",
      },
      timeoutGetTransactions: null,
      currencies: [],
      totals: {
        totalSales: 0,
        totalSpins: 0,
        totalWins: 0,
        totalProfit: 0,
      },
      minDate: null,
      maxDate: null,
    };
  },
  created() {
    this.filterSelect.dateFrom = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00')
    this.filterSelect.dateTo = moment().format('YYYY-MM-DD 23:59')
    this.minDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00')
    this.maxDate = moment().format('YYYY-MM-DD 23:59')
    this.getExchangeRates();
  },
  computed: {
    cardCabezera() {
      return [
        // {
        //   statistic: this.totals.totalSpins,
        //   color: 'success',
        //   icon: "PlayCircleIcon",
        //   title: "Total Spins"
        // },
        // {
        //   statistic: this.totals.totalSales,
        //   color: 'danger',
        //   icon: "ActivityIcon",
        //   title: "Total Sales"
        // },
        // {
        //   statistic: this.totals.totalWins,
        //   color: 'success',
        //   icon: "TrophyIcon",
        //   title: "Total Wins"

        // },
        // {
        //   statistic: this.totals.totalProfit,
        //   color: 'danger',
        //   icon: "DollarSignIcon",
        //   title: "Total Profit"
        // }
      ]
    },
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
    userLoged() {
      return this.$store.getters["usersModule/userLoged"];
    },
  },
  // add filters
  filters: {
    formatDate
  },

  methods: {
    ...mapActions('reports', ['downloadReportFile']),
    async getExchangeRates() {
      const res = await this.$store.dispatch("currenciesModules/getCurrencies");
      this.currencies = res;
    },
    totalSalesToUSD(row) {
      for (let data of this.currencies) {
        if (this.filterSelect.selectCurrency == data.currency) {
          row.totalSalesUSD = row.debit * data.rate;
          return row.totalSalesUSD;
        } else if (this.filterSelect.selectCurrency == "USD") {
          row.totalSalesUSD = row.debit;
          return row.totalSalesUSD;
        }
      }
    },
    totalWinsToUSD(row) {
      for (let data of this.currencies) {
        if (this.filterSelect.selectCurrency == data.currency) {
          row.totalWinsUSD = row.credit * data.rate;
          return row.totalWinsUSD;
        } else if (this.filterSelect.selectCurrency == "USD") {
          row.totalWinsUSD = row.credit;
          return row.totalWinsUSD;
        }
      }
    },
    totalWinloseToUSD(row) {
      for (let data of this.currencies) {
        if (this.filterSelect.selectCurrency == data.currency) {
          row.totalWinloseUSD = row.winlose * data.rate;
          return row.totalWinloseUSD;
        } else if (this.filterSelect.selectCurrency == "USD") {
          row.totalWinloseUSD = row.winlose;
          return row.totalWinloseUSD;
        }
      }
    },
    totalPagarUSD(row) {
      for (let data of this.currencies) {
        if (this.filterSelect.selectCurrency == data.currency) {
          row.totalPagarUSD = row.totalPagar * data.rate;
          return row.totalPagarUSD;
        } else if (this.filterSelect.selectCurrency == "USD") {
          row.totalPagarUSD = row.totalPagar;
          return row.totalPagarUSD;
        }
      }
    },
    downloadFile() {
      this.exportDataLoading = true
      const VUE_APP_GATEWAY = process.env.VUE_APP_GATEWAY;
      const filter = {
        dateFrom: moment(this.filterSelect.dateFrom).utc().format('YYYY-MM-DD HH:mm'),
        dateAt: moment(this.filterSelect.dateTo).utc().format('YYYY-MM-DD HH:mm'),
        customerId: this.filterSelect.selectCustomer,
        currency: this.filterSelect.selectCurrency,
        product: this.filterSelect.selectProduct,
      }
      const finalUrl = VUE_APP_GATEWAY + 'report/profit'
      const format = '.xlsx'
      const customerName = this.customerOptions.find(customer => customer._id == filter.customerId)
      const fileTitle =
        `profit-report-${filter.product}-${customerName?.name.toUpperCase()}-(${filter.dateFrom + ' - ' + filter.dateAt})${format}`

      this.downloadReportFile({ filter: filter, fileTitle: fileTitle, url: finalUrl })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('labels.errorDownloadFile'),
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `${error.message}`,
            },
          });
        })
        .finally(() => this.exportDataLoading = false)

    },
    async searchCustomer(search, loading) {
      loading(true);
      try {
        clearTimeout(this.timeOutSearchCustomer);

        this.timeOutSearchCustomer = setTimeout(async () => {
          const res = await this.$store.dispatch("customerModule/getCustomers", {
            q: search,
            paginate: {
              page: 1,
              perPage: 20,
              sortDesc: true
            }
          });
          this.customerOptions = res.data.customers;
          loading(false);
        }, 500);

        loading(false);
      } catch (error) {
        console.log(error);
      }
    },
    formatTwoDecimals(number) {
      if (number) {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)
      }
      return "-"
    },

    async getTransactions() {
      if (this.filterSelect.selectCurrency === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "You must select a currency",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } else {
        try {
          this.loadingData = true;
          const res = await this.$store.dispatch(
            "reports/getTransactionsProfit",
            { ...this.filterSelect, ...this.serverParams }
          );

          const dataFull = res.data?.transactions.map(item => {
            const rakeBruto = item.debit * 0.045
            const rakeNetoCliente = rakeBruto * 0.81
            const rakeNetoProveedor = rakeBruto * 0.19
            const totalPagar = item.profit + rakeNetoProveedor

            return {
              userId: item.userId,
              username: item.username,
              debit: item.debit,
              credit: item.credit,
              winlose: item.profit,
              rakeBruto: rakeBruto,
              rakeNetoCliente: rakeNetoCliente,
              rakeNetoProveedor: rakeNetoProveedor,
              totalPagar: totalPagar
            }
          })

          if (dataFull.length > 0) {

            let totalDebit = res.data?.totals?.totalSales
            let totalCredit = res.data?.totals?.totalWins
            let totalWinlose = res.data?.totals?.totalProfit
            let totalRakeBruto = totalDebit * 0.045
            let totalRakeNetoCliente = totalRakeBruto * 0.81
            let totalRakeNetoProveedor = totalRakeBruto * 0.19
            let totalPay = totalWinlose + totalRakeNetoProveedor

            dataFull.push({
              userId: 'total',
              username: '',
              debit: totalDebit,
              credit: totalCredit,
              winlose: totalWinlose,
              rakeBruto: totalRakeBruto,
              rakeNetoCliente: totalRakeNetoCliente,
              rakeNetoProveedor: totalRakeNetoProveedor,
              totalPagar: totalPay
            })
          }

          this.tableData = dataFull;
          this.totalRows = res.data.total;
          this.totals = res.data.totals;
        } catch (error) {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching transactions list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          this.loadingData = false;
        }
      }
    },
    fillZero(number, length) {
      var my_string = '' + number;
      while (my_string.length < length) {
        my_string = '0' + my_string;
      }

      return my_string;
    },
    onPageChange(page) {
      console.log(page, "page")
      this.serverParams.page = page;
      this.getTransactions();
    },
    onPerPageChange(limit) {
      this.serverParams.page = 1
      this.serverParams.limit = limit;
      this.getTransactions();
    },
    inputSelect() {
      if ((!this.filterSelect.selectCustomer && this.typeUserLoged == 'Root') || !this.filterSelect.selectProduct || !this.filterSelect.selectCurrency) return;
      this.serverParams.page = 1
      clearTimeout(this.timeoutGetTransactions);
      this.timeout = setTimeout(() => {
        this.getTransactions();
      }, 500);
    },

    selectCustomer(customer) {
      this.productsOptions = customer.products
      this.filterSelect.selectProduct = ''
      if (customer.products && customer.products.length >= 1) {
        this.filterSelect.selectProduct = customer.products[0].productId
      }
      // this.inputSelect()
    },
    async getCustomer() {
      if (this.typeUserLoged !== 'Root') return;
      const res = await this.$store.dispatch("customerModule/getCustomers",
        {
          paginate: {
            page: 1,
            perPage: 500,
            sortDesc: true
          }
        });
      this.customerOptions = res.data.customers;
    },
    async getProducts() {
      if (this.typeUserLoged !== 'Root') {
        const res = await this.$store.dispatch("reports/getProducts");
        this.productsOptions = res.data;

        this.filterSelect.selectCustomer = this.userLoged.customerId
      }
    },
    async getCurrencys() {
      const res = await this.$store.dispatch("reports/getCurrencys");
      //  this.currencyssOptions = res.data.currencys;
    },
    inputDateFrom(date) {
      // minDate restarle 30 dias a la fecha actual 
      // maxDate sumarle 30 dias a la fecha actual
      // this.minDate = moment(date).subtract(29, 'days').format('YYYY-MM-DD 00:00:00')
      // this.maxDate = moment(date).add(29, 'days').format('YYYY-MM-DD 23:59:59')
      // this.filterSelect.dateTo = moment(date).add(29, 'days').format('YYYY-MM-DD 23:59')
    }
  },
  mounted() {
    // Register module
    if (!store.hasModule("reports"))
      store.registerModule("reports", reportsStoreModule);
    this.getProducts();
    // this.getCurrencys();
    this.getCustomer();
  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
